<template>
  <div class="body container">
    <Breadcrumb />
    <Heading :content="this.pageTitle">
      <router-link slot="button" :to="{ name: 'createMonetaryStatement' }">
        <el-button type="primary" size="small">
          <i class="fas fa-plus" />
          新增收支表
        </el-button>
      </router-link>
    </Heading>
    <hr />
    <el-form ref="form" label-width="100px">
      <el-form-item label="查詢類型">
        <el-radio-group v-model="categoryType">
          <el-radio-button label="incomeAndExpense">
            實收款&實付款
          </el-radio-button>
          <el-radio-button label="receivableAndPayable">
            應收款&應付款
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="交易種類">
        <el-radio-group
          v-if="categoryType === 'incomeAndExpense'"
          v-model="search.category"
        >
          <el-radio-button label="all">All</el-radio-button>
          <el-radio-button label="income">實收款</el-radio-button>
          <el-radio-button label="expense">實付款</el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-if="categoryType === 'receivableAndPayable'"
          v-model="search.category"
        >
          <el-radio-button label="all">All</el-radio-button>
          <el-radio-button label="receivable">應收款</el-radio-button>
          <el-radio-button label="payable">應付款</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="學生種類" v-if="categoryType === 'incomeAndExpense'">
        <el-radio-group v-model="search.tag_id">
          <el-radio-button el-radio-button label="all">全部</el-radio-button>
          <el-radio-button
            v-for="userTag in userTags"
            :label="userTag.id"
            :key="userTag.id"
            >{{ userTag.name[lang] }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="查詢日期">
        <el-date-picker
          placeholder="Start"
          v-model="search.start_date"
          type="date"
          value-format="yyyy-MM-dd"
        />
        ~
        <el-date-picker
          placeholder="End"
          v-model="search.end_date"
          type="date"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="查詢項目">
        <el-input v-model="search.keyword" placeholder="来源/項目"></el-input>
      </el-form-item>

      <el-form-item label="每頁數據">
        <el-input-number v-model="search.per_page" :step="5"></el-input-number>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="() => setFilterQuery({ page: 1 })">
          {{ $t("button.search") }}
        </el-button>
      </el-form-item>
    </el-form>
    <hr />
    <el-row type="flex" align="middle">
      <el-col
        :span="24"
        align="right"
        v-if="formQueryCategoryType === 'incomeAndExpense'"
      >
        <template v-if="$route.query.category === 'expense'">
          <span class="summaryLabel">實付款 (TWD)：</span>
          <ExpenseNumber :number="totalExpense" />
        </template>
        <template v-else-if="$route.query.category === 'income'">
          <span class="summaryLabel">實收款 (TWD)：</span>
          <IncomeNumber :number="totalIncome" />
        </template>
        <template v-else>
          <span class="summaryLabel">實付款 (TWD)：</span>
          <ExpenseNumber :number="totalExpense" />
          <span class="summaryLabel">實收款 (TWD)：</span>
          <IncomeNumber :number="totalIncome" />
          <span class="summaryLabel">合計 (TWD)：</span>
          <IncomeNumber v-if="total >= 0" :number="total" />
          <ExpenseNumber v-else :number="total" />
        </template>
      </el-col>
      <el-col
        :span="24"
        align="right"
        v-if="formQueryCategoryType === 'receivableAndPayable'"
      >
        <template v-if="$route.query.category === 'receivable'">
          <span class="summaryLabel">應收款 (TWD)：</span>
          <IncomeNumber :number="totalIncome" />
        </template>
        <template v-else-if="$route.query.category === 'payable'">
          <span class="summaryLabel">應付款 (TWD)：</span>
          <ExpenseNumber :number="-totalIncome" />
        </template>
        <template v-else>
          <span class="summaryLabel">應付款 (TWD)：</span>
          <ExpenseNumber :number="payableTotal" />
          <span class="summaryLabel">應收款 (TWD)：</span>
          <IncomeNumber :number="receivableTotal" />
          <span class="summaryLabel">合計 (TWD)：</span>
          <IncomeNumber
            v-if="receivableAndPayableTotal >= 0"
            :number="receivableAndPayableTotal"
          />
          <ExpenseNumber v-else :number="-receivableAndPayableTotal" />
        </template>
      </el-col>
    </el-row>
    <el-table
      v-if="formQueryCategoryType === 'incomeAndExpense' && monetaryStatements"
      v-loading="!monetaryStatements"
      :data="
        monetaryStatements.filter(item => {
          return item.tradable;
        })
      "
      width="100%"
    >
      <el-table-column label="日期" width="160">
        <template slot-scope="scope">
          <!-- {{ moment(scope.row.updated_at).format("YYYY-MM-DD") }} -->
          {{ instant.formatGoogleTime(scope.row.datetime) }}
        </template>
      </el-table-column>
      <el-table-column label="來源" width="230">
        <template slot-scope="scope">
          <template v-if="scope.row.tradable_type === 'App\\Entities\\User'">
            <router-link
              v-if="scope.row.tradable"
              :to="{
                name: 'userProfile',
                query: { username: scope.row.tradable.username }
              }"
            >
              {{
                userMethods.displayName(
                  scope.row.tradable.first_name,
                  scope.row.tradable.last_name
                )
              }}
            </router-link>
          </template>
          <template
            v-if="
              scope.row.tradable_type === 'order' ||
                scope.row.tradable_type === 'payroll'
            "
          >
            <router-link
              v-if="scope.row.tradable && scope.row.tradable.user"
              :to="{
                name: 'userProfile',
                query: { username: scope.row.tradable.user.username }
              }"
            >
              {{
                userMethods.displayName(
                  scope.row.tradable.user.first_name,
                  scope.row.tradable.user.last_name
                )
              }}
            </router-link>
            <!-- {{ scope.row.target_name }} -->
          </template>
        </template>
      </el-table-column>
      <el-table-column label="項目" prop="item_name">
        <template slot-scope="scope">
          <div v-if="scope.row.tradable_type === 'App\\Entities\\User'">
            <div v-if="scope.row.category === 'income'">
              <el-tag type="success">儲值</el-tag>
              <span v-if="scope.row.remark">
                {{
                  scope.row.remark.indexOf("return_from_order") > -1
                    ? "取消餘額付款"
                    : scope.row.remark
                }}
              </span>
            </div>
            <div v-if="scope.row.category === 'expense'">
              <el-tag type="danger">扣除餘額</el-tag>
            </div>
          </div>
          <div v-if="scope.row.tradable_type === 'order'">
            <router-link
              v-if="scope.row.tradable_id"
              :to="{
                name: 'billView',
                params: { billId: scope.row.tradable_id }
              }"
            >
              <i class="fa fa-eye"></i>
              <span v-if="scope.row.tradable && scope.row.tradable.title">{{
                scope.row.tradable.title
              }}</span>
            </router-link>
          </div>
          <div v-if="scope.row.tradable_type === 'payroll'">
            <router-link
              v-if="scope.row.tradable_id && scope.row.tradable.user.id"
              :to="{
                name: 'SalaryView',
                params: {
                  teacherId: scope.row.tradable.user.id,
                  salaryId: scope.row.tradable_id
                }
              }"
            >
              <i class="fa fa-eye"></i>
              <span v-if="scope.row.tradable && scope.row.tradable.title">
                {{ scope.row.tradable.title }}
              </span>
            </router-link>
          </div>
          <!-- <div>
            <span v-if="scope.row.tradable && scope.row.tradable.title">
              {{ scope.row.tradable.title }}
            </span>
          </div> -->
        </template>
      </el-table-column>
      <el-table-column label="金額 (TWD)" width="200" align="right">
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.original_currency !== 'TWD'"
            :content="
              `≈$${MoneyCalculator.addThousandsComma(
                Number(scope.row.original_price)
              )} ${scope.row.original_currency}`
            "
            placement="top"
          >
            <IncomeNumber
              v-if="scope.row.category === 'income'"
              :number="scope.row.price_twd"
            />
            <ExpenseNumber
              v-else-if="scope.row.category === 'expense'"
              :number="scope.row.price_twd"
            />
          </el-tooltip>
          <template v-else>
            <IncomeNumber
              v-if="scope.row.category === 'income'"
              :number="scope.row.price_twd"
            />
            <ExpenseNumber
              v-else-if="scope.row.category === 'expense'"
              :number="scope.row.price_twd"
            />
          </template>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Action" width="100">
        <template slot-scope="scope">
          <template v-if="true">
            <span @click="displayOtherItemActionWarningMessage">
              <i class="fas fa-edit action-icon" />
            </span>
            <span @click="displayOtherItemActionWarningMessage">
              <i class="fas fa-trash-alt warning-icon" />
            </span>
          </template>
          <template v-else>
            <router-link
              :to="{
                name: 'editMonetaryStatement',
                params: { id: scope.row.id }
              }"
            >
              <i class="fas fa-edit action-icon" />
            </router-link>
            <DeleteIconButton
              :onDelete="deleteMonetaryStatement(scope.row.id)"
            />
          </template>
        </template>
      </el-table-column> -->
    </el-table>
    <el-table
      v-loading="!monetaryStatements"
      :data="monetaryStatements"
      width="100%"
      v-if="formQueryCategoryType === 'receivableAndPayable'"
    >
      <el-table-column label="日期" width="160">
        <template slot-scope="scope">
          <!-- {{ moment(scope.row.started_at).format("YYYY-MM-DD") }} -->
          {{ instant.formatGoogleTime(scope.row.started_at) }}
        </template>
      </el-table-column>
      <el-table-column label="用户" width="230">
        <template slot-scope="scope">
          <template>
            <router-link
              v-if="scope.row.item_type === 'lesson_log'"
              :to="{
                name: 'ProfileForAdmin',
                params: { id: scope.row.student_user_id }
              }"
            >
              {{
                userMethods.displayName(
                  scope.row.first_name,
                  scope.row.last_name
                )
              }}
            </router-link>
            <router-link
              v-if="
                scope.row.item_type === 'teaching_log' ||
                  scope.row.item_type === 'monthly_salary'
              "
              :to="{
                name: 'ProfileForAdmin',
                params: { id: scope.row.teacher_user_id }
              }"
            >
              {{
                userMethods.displayName(
                  scope.row.first_name,
                  scope.row.last_name
                )
              }}
            </router-link>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="項目" prop="item_name">
        <template slot-scope="scope">
          <template
            v-if="
              scope.row.item_type === 'lesson_log' ||
                scope.row.item_type === 'teaching_log'
            "
          >
            <router-link
              v-if="scope.row.id && scope.row.id > 0"
              :to="{
                name: 'LessonLogDetail',
                query: {
                  isTeachingLog: scope.row.item_type === 'teaching_log' ? 1 : 0
                },
                params: {
                  lessonLogId: scope.row.id
                }
              }"
              v-html="
                scope.row.title ? scope.row.title : '<em>- 沒有標題 -</em>'
              "
            >
            </router-link>
            <span v-else>
              {{ scope.row.title }}
            </span>
          </template>
          <template v-else-if="scope.row.item_type === 'monthly_salary'">
            月薪
          </template>
          <template v-else>
            {{ scope.row.item_name }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="金額" width="300" align="right">
        <template slot-scope="scope">
          <template v-if="scope.row.fee_twd">
            <el-tooltip
              v-if="scope.row.fee_currency !== 'TWD'"
              :content="
                `≈$${MoneyCalculator.addThousandsComma(
                  Number(scope.row.fee)
                )} ${scope.row.fee_currency}`
              "
              placement="top"
            >
              <IncomeNumber :number="scope.row.fee_twd" />
            </el-tooltip>
            <template v-else>
              <IncomeNumber :number="scope.row.fee_twd" />
            </template>
          </template>
          <template v-if="scope.row.salary">
            <el-tooltip
              v-if="scope.row.salary_currency !== 'TWD'"
              :content="
                `≈$${MoneyCalculator.addThousandsComma(
                  Number(scope.row.salary)
                )} ${scope.row.salary_currency}`
              "
              placement="top"
            >
              <ExpenseNumber :number="scope.row.salary_twd" />
            </el-tooltip>
            <template v-else>
              <ExpenseNumber :number="scope.row.salary_twd" />
            </template>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="已结算" width="100" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.item_type === 'lesson_log'">
            <template v-if="scope.row.payment_status === 1">
              <span class="text-success"><i class="fas fa-check-circle"/></span>
            </template>
          </template>
          <template v-if="scope.row.item_type === 'teaching_log'">
            <template v-if="scope.row.salary_is_paid === 1">
              <span class="text-success"><i class="fas fa-check-circle"/></span>
            </template>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="center">
      <el-col :span="24" align="center">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="search.per_page"
          :current-page="search.page"
          @current-change="page => setFilterQuery({ page })"
          :total="monetaryStatementCount"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import "@/assets/scss/style.scss";
import moment from "moment";
import { user, MoneyCalculator } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import ExpenseNumber from "@/components/monetaryStatement/ExpenseNumber";
import IncomeNumber from "@/components/monetaryStatement/IncomeNumber";
import monetaryStatementApi from "@/apis/monetaryStatement";
import tagApi from "@/apis/tag";
import SatMyBestScore from "@/components/userProfile/SatMyBestScore";
import { instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    // DeleteIconButton,
    ExpenseNumber,
    IncomeNumber
  },
  data() {
    return {
      categoryType: "incomeAndExpense",
      search: {
        page: 1,
        start_date: null,
        end_date: null,
        tag_id: "all",
        category: "all",
        keyword: null,
        per_page: 15
      },
      userTags: [],
      totalIncome: 0,
      totalExpense: 0,
      monetaryStatements: null,
      monetaryStatementCount: 0,
      receivableTotal: 0,
      payableTotal: 0,
      receivableAndPayableTotal: 0
    };
  },
  computed: {
    instant() {
      return instant;
    },
    moment() {
      return moment;
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    MoneyCalculator() {
      return MoneyCalculator;
    },
    userMethods() {
      return user;
    },
    // pageSize() {
    //   return 15;
    // },
    pageTitle() {
      return this.$t("pageTitle.monetaryStatement");
    },
    total() {
      return this.totalIncome - this.totalExpense;
    },
    formQueryCategoryType() {
      return this.$route.query?.categoryType || "incomeAndExpense";
    }
  },
  watch: {},
  async created() {
    this.categoryType = this.$route.query?.categoryType || "incomeAndExpense";
    this.search.start_date =
      this.$route.query?.start_date ||
      this.moment()
        .subtract(30, "day")
        .format("YYYY-MM-DD");
    this.search.end_date =
      this.$route.query?.end_date || this.moment().format("YYYY-MM-DD");
    this.search.page = Number(this.$route.query?.page) || null;
    this.search.tag_id = this.$route.query?.tag_id || "all";
    this.search.category = this.$route.query?.category || "all";
    this.search.keyword = this.$route.query?.keyword || null;
    this.search.per_page = Number(this.$route.query?.per_page) || 15;

    if (this.categoryType === "incomeAndExpense") {
      // this.fetchMonetaryStatements();
      this.fetchTransaction();
    } else if (this.categoryType === "receivableAndPayable") {
      this.fetchReceivableAndPayable();
    }
    this.getUserTags();
  },
  methods: {
    async getUserTags() {
      const { tags } = await tagApi.fetchTags("student");
      const filterTags = ["台灣學生", "中國學生"];
      this.userTags = filterTags.map(tag =>
        tags.find(({ name: { tw } }) => tw === tag)
      );
    },
    getPrivateType(sessionId) {
      const counselingSessionIds = [5, 6];
      if (counselingSessionIds.includes(sessionId)) {
        return "counseling";
      }
      return "private-lessons";
    },
    displayOtherItemActionWarningMessage() {
      this.$message.warning(
        "如果要編輯或刪除此收支項目，請到對應的課程或薪資帳單中操作！"
      );
    },
    async fetchMonetaryStatements() {
      const {
        transaction_statements: { data, total },
        total_expense_price,
        total_income_price
      } = await monetaryStatementApi.fetchMonetaryStatements({
        ...this.search,
        tag_id:
          this.search.tag_id === "all" ? null : Number(this.search.tag_id),
        category: this.search.category === "all" ? null : this.search.category
      });
      this.monetaryStatements = data;
      this.monetaryStatementCount = total;
      this.totalIncome = Number(total_income_price);
      this.totalExpense = Number(total_expense_price);
    },
    async fetchTransaction() {
      const {
        transaction_statements: { data, total },
        total_expense_price,
        total_income_price
      } = await monetaryStatementApi.fetchTransaction({
        ...this.search,
        tag_id:
          this.search.tag_id === "all" ? null : Number(this.search.tag_id),
        category: this.search.category === "all" ? null : this.search.category
      });
      this.monetaryStatements = data;
      this.monetaryStatementCount = total;
      this.totalIncome = Number(total_income_price);
      this.totalExpense = Number(total_expense_price);
    },
    async fetchReceivableAndPayable() {
      if (this.search.category === "receivable") {
        const {
          account_payable: { data, total },
          sum
        } = await monetaryStatementApi.fetchReceivableAndPayable({
          category: "account_receivable",
          start_date: this.search.start_date,
          end_date: this.search.end_date,
          page: this.search.page,
          keyword: this.search.keyword,
          per_page: this.search.per_page
        });
        this.monetaryStatements = data;
        this.monetaryStatementCount = total;
        this.totalIncome = Number(sum);
        this.totalExpense = Number(0);
      } else if (this.search.category === "payable") {
        const {
          account_payable: { data, total },
          sum
        } = await monetaryStatementApi.fetchReceivableAndPayable({
          category: "account_payable",
          start_date: this.search.start_date,
          end_date: this.search.end_date,
          page: this.search.page,
          keyword: this.search.keyword,
          per_page: this.search.per_page
        });
        this.monetaryStatements = data;
        this.monetaryStatementCount = total;
        this.totalIncome = Number(sum);
        this.totalExpense = Number(0);
      } else {
        const {
          account_payable: { data, total },
          sum,
          sum_payable,
          sum_receivable
        } = await monetaryStatementApi.fetchReceivableAndPayable({
          start_date: this.search.start_date,
          end_date: this.search.end_date,
          page: this.search.page,
          keyword: this.search.keyword,
          per_page: this.search.per_page
        });
        this.monetaryStatements = data;
        this.monetaryStatementCount = total;
        this.receivableTotal = sum_receivable;
        this.payableTotal = sum_payable;
        this.receivableAndPayableTotal = Number(sum);
      }
    },
    setFilterQuery(search = {}) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          ...this.search,
          ...search,
          categoryType: this.categoryType
        }
      });
    },
    deleteMonetaryStatement(monetaryStatementId) {
      return async () => {
        await monetaryStatementApi.deleteMonetaryStatement(monetaryStatementId);
        this.fetchMonetaryStatements();
      };
    }
  }
};
</script>

<style scoped>
::v-deep .el-pagination {
  margin: 20px 0px;
}

::v-deep .el-step__description {
  padding-right: 0px;
}

.searchBtn {
  margin: 0 8px;
}

.summary {
  margin-top: 12px;
}

.summaryLabel {
  margin: 0 8px;
}

.originalCurrency {
  color: #888;
}
</style>
