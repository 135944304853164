<template>
  <span class="expense">
    <span>{{ `-$${MoneyCalculator.addThousandsComma(Number(number))}` }}</span>
  </span>
</template>

<script>
import { MoneyCalculator } from "@ivy-way/material";

export default {
  props: ["number"],
  computed: {
    MoneyCalculator() {
      return MoneyCalculator;
    }
  }
};
</script>

<style lang="scss" scoped>
.expense {
  color: #FFFFFF;
  background-color: #ff5252;
  padding: 2px 6px;
  border-radius: 4px;
}
</style>
